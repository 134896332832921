/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                //$('body').scrollspy({target: ".first-navigation", offset: 50});

                // Add smooth scrolling on all links inside the navbar
                $("#products-navigation .dropdown-toggle").on('click', function(event) {

                  // Make sure this.hash has a value before overriding default behavior
                  //if (this.hash !== "") {

                    // Prevent default anchor click behavior
                    //event.preventDefault();

                    // Store hash
                    var hash = this.hash;

                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                      scrollTop: $(hash).offset().top - 70
                    }, 'slow', function(){

                    // Add hash (#) to URL when done scrolling (default click behavior)
                      //window.location.hash = hash;
                    });

                  //} // End if

                });




                $('.open-products').on('click', function (e) {
                    /*$('.navigation-products').css('display', 'none');*/
                    e.stopPropagation();
                    $(this).find('.navigation-products').css('display', 'inline-block');
                });

                $('.open-products').on('click', function (e) {
                    $('.open-products').css('border-bottom', '1px solid #b9c3c9');
                    $('.tab-content').find('.tab-pane').removeClass('active');

                    $(this).css('border-bottom', '5px solid red');
                    e.stopPropagation();
                    var id = $(this).data('product-id');
                    $('.tab-content').find('#' + id).addClass('active');
                });

                $('li.dropdown.products .dropdown-toggle').on('click', function (event) {
                    $('li.dropdown.products').removeClass("open");
                    $(this).parent().toggleClass('open');
                });

                $('body').on('click', function (e) {
                    if (!$('li.dropdown.products').is(e.target) && $('li.dropdown.products').has(e.target).length === 0 && $('.open').has(e.target).length === 0) {
                        $('li.dropdown.products').removeClass('open');
                    }
                });

                $('.close').on('click', function (e) {
                    $('li.dropdown.products').removeClass('open');
                });

                $(document).on("scroll", function () {
                    if ($(document).scrollTop() > 100) {
                        $(".header").addClass("small");
                    } else {
                        $(".header").removeClass("small");
                    }
                });

                $('.primary-menu a').on('click', function (e) {
                    $('.primary-menu a').css('border-bottom', '0');
                    $(this).css('border-bottom', '5px solid #cd2327');
                });

                $(".show-ask-us-modal a").click(function(){
                    $("#ask-us-modal").modal('show');
                });

                function add_marker($marker, map) {
                    // var
                    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
                    // create marker
                    var marker = new google.maps.Marker({
                        position: latlng,
                        map: map
                    });
                    // add to array
                    map.markers.push(marker);
                    // if marker contains HTML, add it to an infoWindow
                    if ($marker.html()) {
                        // create info window
                        var infowindow = new google.maps.InfoWindow({
                            content: $marker.html()
                        });
                        // show info window when marker is clicked
                        google.maps.event.addListener(marker, 'click', function () {

                            infowindow.open(map, marker);
                        });
                    }
                }

                function center_map(map) {
                    // vars
                    var bounds = new google.maps.LatLngBounds();
                    // loop through all markers and create bounds
                    $.each(map.markers, function (i, marker) {
                        var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
                        bounds.extend(latlng);
                    });
                    // only 1 marker?
                    if (map.markers.length === 1) {
                        // set center of map
                        map.setCenter(bounds.getCenter());
                        map.setZoom(16);
                    }
                    else {
                        // fit to bounds
                        map.fitBounds(bounds);
                    }
                }

                function new_map($el) {
                    // var
                    var $markers = $el.find('.marker');
                    // vars
                    var args = {
                        zoom: 16,
                        center: new google.maps.LatLng(0, 0),
                        mapTypeId: google.maps.MapTypeId.ROADMAP
                    };
                    // create map
                    var map = new google.maps.Map($el[0], args);
                    // add a markers reference
                    map.markers = [];
                    // add markers
                    $markers.each(function () {

                        add_marker($(this), map);
                    });
                    // center map
                    center_map(map);
                    // return
                    return map;
                }

                $('.acf-map').each(function(){
                  // create map
                  map = new_map( $(this) );
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
